var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "searchTerm"
    }
  }, [_c('b-form-input', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search by Task Name ...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch,
      "input": _vm.taskFiltering
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterProjectIdOption,
      "reduce": function reduce(task) {
        return task.id;
      },
      "label": "name",
      "placeholder": "Select Project"
    },
    on: {
      "input": _vm.filterProject
    },
    model: {
      value: _vm.filterProjectId,
      callback: function callback($$v) {
        _vm.filterProjectId = $$v;
      },
      expression: "filterProjectId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterMilestoneBoardIdOption,
      "reduce": function reduce(board) {
        return board.id;
      },
      "label": "name",
      "placeholder": "Select Milestone Board"
    },
    on: {
      "input": _vm.filterMilestoneBoard
    },
    model: {
      value: _vm.filterMilestoneBoardId,
      callback: function callback($$v) {
        _vm.filterMilestoneBoardId = $$v;
      },
      expression: "filterMilestoneBoardId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterAllUserIdOption,
      "reduce": function reduce(member) {
        return member.id;
      },
      "label": "name",
      "placeholder": "Select Supervisor"
    },
    on: {
      "input": _vm.filterSupervisor
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.filterSupervisorId,
      callback: function callback($$v) {
        _vm.filterSupervisorId = $$v;
      },
      expression: "filterSupervisorId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterTaskStatusOption,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.filterTaskStatusData
    },
    model: {
      value: _vm.filterTaskStatus,
      callback: function callback($$v) {
        _vm.filterTaskStatus = $$v;
      },
      expression: "filterTaskStatus"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Deadline",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.filterDeadline
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)])], 1), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row4, _props$row5, _props$row5$phase, _props$row5$phase$dat, _props$row6, _props$row6$phase, _props$row6$phase$dat, _props$row7, _props$row8, _props$column2, _props$row9, _props$row10, _props$row12, _props$column3, _props$row13, _props$row13$phase, _props$row13$phase$da, _props$column4, _props$row14, _props$row14$mileston, _props$row14$mileston2, _props$row14$mileston3, _props$column5, _props$row15, _props$row16, _props$row17, _props$row18, _props$row19, _props$row20;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_tasks' ? [_c('span', [props !== null && props !== void 0 && (_props$row = props.row) !== null && _props$row !== void 0 && _props$row.is_completed ? _c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Completed ")]) : _vm._e(), props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.overdue ? _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Overdue ")]) : _vm._e(), _c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row3, _props$row3$milestone, _props$row3$milestone2;
              return _vm.onShowTaskDetailsPage(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$milestone = _props$row3.milestoneTaskBoard) === null || _props$row3$milestone === void 0 ? void 0 : (_props$row3$milestone2 = _props$row3$milestone.data) === null || _props$row3$milestone2 === void 0 ? void 0 : _props$row3$milestone2.id, props.row.id, props.row.short_title);
            }
          }
        }, [_c('div', {
          staticClass: "font-weight-bold",
          staticStyle: {
            "color": "black"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.breakTitle(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.title))
          }
        })])], 1), _c('div', [_vm._v(" Phase: "), props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && (_props$row5$phase = _props$row5.phase) !== null && _props$row5$phase !== void 0 && (_props$row5$phase$dat = _props$row5$phase.data) !== null && _props$row5$phase$dat !== void 0 && _props$row5$phase$dat.title ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$phase = _props$row6.phase) === null || _props$row6$phase === void 0 ? void 0 : (_props$row6$phase$dat = _props$row6$phase.data) === null || _props$row6$phase$dat === void 0 ? void 0 : _props$row6$phase$dat.title) + " ")])] : _c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" N / A ")])], 2), _c('div', [props !== null && props !== void 0 && (_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.word_count ? [_vm._v(" Word Count: "), _c('b-badge', {
          attrs: {
            "variant": "light-info"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.word_count) + " ")])] : _vm._e()], 2)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_member_info' ? [_vm._l(props.row.team.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.team_count) > 0 ? _c('span', [(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.team_count) > 3 ? _c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row11, _props$row11$team;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : (_props$row11$team = _props$row11.team) === null || _props$row11$team === void 0 ? void 0 : _props$row11$team.data);
            }
          }
        }, [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.team_count) - 3) + " more ")])], 1) : _vm._e()]) : _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" N / A ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_phase_info' ? [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : (_props$row13$phase = _props$row13.phase) === null || _props$row13$phase === void 0 ? void 0 : (_props$row13$phase$da = _props$row13$phase.data) === null || _props$row13$phase$da === void 0 ? void 0 : _props$row13$phase$da.title) + " ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_supervisor' ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : (_props$row14$mileston = _props$row14.milestoneTaskBoard) === null || _props$row14$mileston === void 0 ? void 0 : (_props$row14$mileston2 = _props$row14$mileston.data) === null || _props$row14$mileston2 === void 0 ? void 0 : (_props$row14$mileston3 = _props$row14$mileston2.supervisor) === null || _props$row14$mileston3 === void 0 ? void 0 : _props$row14$mileston3.data
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_date' ? [_c('b', [_vm._v("Deadline: ")]), props !== null && props !== void 0 && (_props$row15 = props.row) !== null && _props$row15 !== void 0 && _props$row15.deadline ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.deadline)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual Start Date: ")]), props !== null && props !== void 0 && (_props$row17 = props.row) !== null && _props$row17 !== void 0 && _props$row17.actual_start_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.actual_start_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1), _c('br'), _c('b', [_vm._v("Actual End Date: ")]), props !== null && props !== void 0 && (_props$row19 = props.row) !== null && _props$row19 !== void 0 && _props$row19.actual_end_date ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : _props$row20.actual_end_date)) + " ")]) : _c('span', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" N/A ")])], 1)] : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '20', '50']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member-form",
      "centered": "",
      "title": "Assigned Members",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assingedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }